<template>
    <section class="container-fluid px-md-5 p-3 bg-white">
        <div class="row  p-2 my-5">
            <div class="col-12">
                <div class="row justify-content-between align-items-center">
                    <h2 class=" text-pri p-2 radius-10 f-bold ">NEW ARRIVALS</h2>
                    <a href="" class="text-sec">explore more</a>
                </div>
            </div>
         
            <div class="col-12">
                <div class="container-fluid">
                    <div class="row justify-content-center align-items-center p-md-5 p-2">
                  
                   <productItem v-for="product in items" :key="product.id" :product="product" />
                 
                    
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>
<script> 


  import productItem from '@/components/productItem.vue'

import { mapState } from 'vuex';

export default {
     name: "productSection",
  components: {
    productItem
  },
  mounted(){
    
      this.$store.dispatch('fetchItems');
    
  },
 computed: {
    ...mapState(['items', 'loading', 'error']),
  },
  methods: {
   // loadItems() {
     // this.$store.dispatch('fetchItems');
    //},
  },
  // computed: {
  //   ...mapState("product", ["products"])
  // },



  // methods: {
  //   ...mapActions("product", ["getProducts"])
  // }
};
 
</script>