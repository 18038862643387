<template>
<appNav/>
<header class="container-fluid p-md-5 p-3 mt-5 mb-3">
   
        <div class="row justify-content-between align-items-start">
    <div class="col-12 my-2">
        <h1 class="text-pri text-center"><span class="fa fa-shopping-cart mx-2"></span>SHOPPING CART </h1>
    </div>
    <div class="col-md-7 col-12">
        <div class="card mt-5">
            <div class="card-header bg-pri text-white f-bold">
              cart contents
            </div>

            <div class="card-body">

                <div class="table-responsive">
                    <table class="table  table-striped table-inverse  w-100">
                        <thead>
                            <tr class="f-bold">
                                <td>item</td>
                                <td>qty</td>
                                <td>size</td>
                                <td>price</td>
                                <td>total</td>
                                <td>action</td>
                            </tr>
                        </thead>
                        <tbody>
                          
                                    <tr v-for="product in cartItems" :key="product.id" :product="product">
                                      
                                        <td>{{ product.name }}</td>
                                        <td>{{ product.quantity}}</td>
                                        <td>{{  }}</td>
                                        <td>{{}}</td>
                                        <td>{{ }}</td>
                                        <td> <a >
                                                <span  @click="removeFromCart(product.id)" class="fa fa-trash "></span>
    
                                            </a>
                                            <a>
                                                <span class="fa fa-gift mx-2" style="font-size:20px"></span>
    
    
                                            </a>
                                            <!-- @livewire('product-quantity', ['item' => $item] , key($item['id'])) -->
                                        </td>
    
                                    </tr>
                            
                        </tbody>
                    </table>
                </div>

            </div>
            <!-- <div class="card-footer">
                <strong> @lang('cart.total_quantity')</strong> {{ $total_quantity }}
            </div> -->
        </div>


    </div>
    <!-- <div class="col-md-4 col-12">
        <div class="card mt-5">
            <div class="card-header bg-success text-white f-bold">
                @lang('cart.summary')
            </div>
            <div class="card-body d-flex flex-column">

                <p><strong>@lang('cart.total') : {{ $total }} </strong></p>

                <p><strong>@lang('cart.discount') : {{ $totalOffer }} </strong> </p>
                @if (session()->has('coupondone'))
                    <span class="text-success" role="alert">
                        {{ session('coupondone') }}
                    </span>
                @endif
                @if (session()->has('message'))
                    <span class="text-danger" role="alert">
                        {{ session('message') }}
                    </span>
                @endif
                <p><strong>@lang('cart.net') : {{ $totalPrice }}</strong></p>
            </div>
            <div class="card-footer">
                @if ($cart)
                    <a href="{{ route('checkout') }}" class="btn bg-pri w-100 text-white">@lang('cart.continue')</a>
                @else
                    <a href="" class="btn bg-pri w-100 text-white">@lang('cart.continue')</a>
                @endif
            </div>
        </div>
    </div> -->

  
</div>

     
</header>

</template>

  
  <script>
    
 import appNav from '@/components/layouts/appNav.vue'


import { mapGetters, mapActions } from 'vuex';
  export default {
    name: 'cart',
    components: {
      appNav,

  },
  computed: {
    ...mapGetters(['cartItems']),
    total() {
      return this.cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
    },
  },
   methods: {
    ...mapActions(['removeFromCart']),
  },


  }
  </script>