export default {

    items: (state) => state.items,

    cartItems: state => state.cart,
    cartCount: state => state.cart.reduce((count, item) => count + item.quantity, 0),

    getProduct(state) {
        return (id) => {
          return state.items.find((product) => {
            return product.id == id

          })
        }},
        user (state) {
          return state.user
        },
        loading (state) {
          return state.loading
        },
        error (state) {
          return state.error
        }

        
}