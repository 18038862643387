import axios from "axios"
import router from '@/router/index'
export default {

    async fetchItems({ commit }) {
        commit('setLoading', true);
        try {
          const response = await axios.get('https://gtcog.com/api/products');
          commit('SET_ITEMS', response.data);
        } catch (error) {
          commit('setError', error);
        } finally {
          commit('setLoading', false);
        }
    },
    addToCart({ commit }, item) {
        if(localStorage.getItem('user')){
             commit('ADD_TO_CART', item);
       }else{
        console.log('c2 sign')
        router.push('/signin')
       }
      
    },
    removeFromCart({ commit }, itemId) {
      commit('REMOVE_FROM_CART', itemId);
    },
   
    signUserIn ({commit}, payload) {
      commit('setLoading', true)
      commit('clearError')
      console.log('signUserIn')
      axios
      .post('https://gtcog.com/api/login', payload)
      .then((res) => {
        console.log(res)
             
            commit('setLoading', false)
            const newUser = {
              id: res.data.id,
              token : res.data.id,
            }
          
            commit('setUser', newUser)
          
         
        
      }).catch(
            error => {
              commit('setLoading', false)
              commit('setError', error)
              console.log(error)
            }
          )
      // firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      //   .then(
      //     user => {
      //       commit('setLoading', false)
      //       const newUser = {
      //         id: user.uid,
      //         registeredMeetups: []
      //       }
      //       commit('setUser', newUser)
      //     }
      //   )
      //   .catch(
      //     error => {
      //       commit('setLoading', false)
      //       commit('setError', error)
      //       console.log(error)
      //     }
      //   )
    },
 
    logout ({commit}) {
    //  firebase.auth().signOut()
   // localStorage.setItem('user', null)
    
      commit('setUser', null)
    },

    clearError ({commit}) {
      commit('clearError')
    }
}