import { createWebHistory, createRouter } from 'vue-router'

import Home from '../views/Home.vue'
import Cart from '../views/cart.vue'
import Product from '../views/product.vue'
//import Signup from '../views/Signup'
import Signin from '../views/Signin'
import AuthGuard from './auth-guard'
//import authRedirect from './authRedirect'

const routes = [
  { path: '/', component: Home  ,  name: 'home', },
  { path: '/cart', component: Cart ,  name: 'cart',
    beforeEnter: AuthGuard
  },
   {  path: '/product/:id',
    name: 'product',
    component: Product,
    props: true,
  } ,
  // {
  //   path: '/signup',
  //   name: 'Signup',
  //   component: Signup
  // },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
   // beforeEnter: authRedirect
  },
 
  
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})


export default router