
import Vuex from 'vuex'


import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const loadCartFromLocalStorage = () => {
  const storedCart = localStorage.getItem('cart');
  return storedCart ? JSON.parse(storedCart) : [];
};
const loadUserFromLocalStorage = () => {
  const storedUser = localStorage.getItem('user');
  return storedUser ? JSON.parse(storedUser) : null;
};

 const store = new Vuex.Store({

    state:{
        items: [],
        loading: false,
        error: null,
        
        cart: loadCartFromLocalStorage(),

        user:loadUserFromLocalStorage(), 
        
    },
    getters,
    mutations,
    actions,
  
  
    //change data befor send
  //  getters:{
        // modifiedInfo:state =>{
        //     let infoPerson = state.names.map(x =>{
        //         return{
        //             name: 'Name: ' + x.name ,
        //             salary: x.salary
        //         }
        //     })
        //     return infoPerson
        // },
  //  },

    //change data by action
   // mutations:{
        // increateSalary:state=>{
        //     return  state.names.forEach(x=>{
        //         x.salary += 200;
        // })},
        // decreateSalary:state=>{
        //     return  state.names.forEach(x=>{
        //         x.salary -= 200;
        // })},
   // },
 

  
   
})
 
export default store