<template>
  <div class="container d-flex align-items-center" style="height: 100vh">
    <div class="mx-auto col-5">
      <h2 class=" d-flex  justify-content-center my-4">Sign in</h2>
      <form @submit.prevent="onSignin"> 
        <div  class="form-outline mb-4">
          <label class="form-label" for="form2Example1">Email address</label>
          <input type="email"  v-model="email" id="form2Example1" class="form-control" />
        
        </div>

        <div class="form-outline mb-4">
          <label class="form-label" for="form2Example2">Password</label>
          <input type="password" v-model="password" id="form2Example2" class="form-control" />
        
        </div>

        <button
          type="submit" 
          class="btn btn-primary btn-block mb-4  "
        >
          Sign in 
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
     data () {
      return {
        email: '',
        password: ''
      }
    },
    mounted() {
           console.log('c')
      //  if(localStorage.getItem('user')){
      //        console.log('c2')
      //        this.$router.push('/')
      //  }
    },
     computed: {
      user () {
        return this.$store.getters.user
      },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      }
    },
    watch: {
      user (value) {
     
        if (value !== null && value !== undefined) {
              console.log('wa')
          this.$router.push('/')
        }
      }
    },
    methods: {
      onSignin () {
         console.log('log')
        this.$store.dispatch('signUserIn', {email: this.email, password: this.password})
      },
    //   onDismissed () {
    //     this.$store.dispatch('clearError')
    //   }
    },
};
</script>
