

export default {

 
      ADD_TO_CART(state, item) {
        

      const existingItem = state.cart.find(i => i.id === item.id);
      if (existingItem) {
        existingItem.quantity += 1; // Increase quantity if item exists
      } else {
        state.cart.push({ ...item, quantity: 1 }); // Add new item
      }
      localStorage.setItem('cart', JSON.stringify(state.cart)); // Save cart to local storage
  
   
      },
      REMOVE_FROM_CART(state, itemId) {
        state.cart = state.cart.filter(item => item.id !== itemId);
        localStorage.setItem('cart', JSON.stringify(state.cart)); // Update local storage
      },
        SET_ITEMS(state, items) {
          state.items = items;
        },
    

        setUser (state, payload) {
          state.user = payload
          if(state.user == null )
          {
            localStorage.setItem('user', null);
            console.log('localStorage.getItem()');
            console.log(localStorage.getItem('user'));
          }else{
            localStorage.setItem('user', JSON.stringify(state.user));
          }
        
        },
        setLoading (state, payload) {
          state.loading = payload
        },
        setError (state, payload) {
          state.error = payload
        },
        clearError (state) {
          state.error = null
        }
}