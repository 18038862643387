<template>
  <nav class="navbar navbar-expand-lg bg-pri text-white">
    <a href="" class="navbar-brand"
      ><img
        class="img-fluid"
        width="100%"
        src="@/assets/images/logo.webp"
        alt="GTCOG logo"
    /></a>
    <button
      class="navbar-toggler"
      data-target="#my-nav"
      data-toggle="collapse"
      aria-controls="my-nav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="my-nav" class="collapse navbar-collapse bg-pri text-white">
      <ul class="navbar-nav mr-auto ml-5 ">
        <li class="nav-item active text-white">
        
          <a class="nav-link text-white" 
            ><router-link :to="{name: 'home'}">home <span class="sr-only">(current)</span> </router-link></a>
        </li>
        <li class="nav-item ">
          <a class="nav-link text-white" href="">brands <span class="sr-only"></span></a>
        </li>
      </ul>
      <ul class="navbar-nav d-flex flex-row justify-content-between ml-auto mr-5">
   
        <li v-if="userIsAuthenticated" class="nav-item"  > <a class="nav-link "  >{{total}} <router-link :to="{name: 'cart'}">
        <span  class="fa fa-shopping-cart text-white"></span>
          </router-link>
           </a></li>

        <li class="nav-item">
    
         <a   v-if="!userIsAuthenticated" class="nav-link search-icon"><router-link :to="{name: 'Signin'}"> sign in <span class="fa fa-sign-in text-white"></span>
            </router-link></a>
               <a   v-if="userIsAuthenticated"   @click.prevent="onSignout" class="nav-link search-icon">sign out<span class="fa fa-sign-out text-white"></span>
           </a>
        </li>

        <a class="nav-link" href=""> <span class="fa fa-heart-o text-white"></span></a>
      </ul>
    </div>
  </nav>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css"
    media="screen"
  />
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  name: "appNav",
   data () {
  //     return {
  // user:  localStorage.getItem('user')
    
  //     };
      },

   computed: {
    ...mapGetters(['cartItems' ]),
    
    total() {
      return this.cartItems.length ;
    },
       userIsAuthenticated () {
        console.log(this.$store.getters.user )
        return this.$store.getters.user !== null && this.$store.getters.user !== undefined
      }
    
  },
      methods: {
      onSignout () {
         console.log('out')
        this.$store.dispatch('logout')
      },
 
    },
    //   watch: {
    //   user (value) {
     
    //     if (value !== null && value !== undefined) {
    //           console.log('wa hihi')
    //   return null
    //     }
    //   }
    // },
};
</script>

<style>
.text-pri {
  color: #294b93;
}
.text-sec {
  color: #4c4c4c;
}
.text-white {
  color: white m !important;
}
.bg-pri {
  background-color: #294b93 !important;
}
.bg-sec {
  background-color: #4c4c4c !important;
}

.text-white {
  color: white;
}
.nav-link {
  color: #2064a4;
}
.nav-item.active .nav-link {
  color: #2064a4;
  font-weight: bold;
}
.navbar {
  background: white;
  padding: 1rem;
  border: thin solid #2064a4;
}
.navbar-collapse {
  background: white;
}

.navbar-brand {
  background: white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  z-index: 2;
  /*    border: 2px dashed #2064a4; */
}

.navbar-brand img {
  margin: auto;
  display: block;
}
</style>
