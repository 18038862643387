<template>

  <div class="col-md-3 col-sm-6 col-12 my-2">
 
    <div class="product-item bg-white" data-aos="fade-up">
      <p class="category">{{ product.category }}</p>

      <p class="brand">{{ product.brand }}</p>
 
      <a href=""> <router-link :to="{name: 'product', params: {id: product.id}}">
        <img class="d-block mx-auto" v-bind:src="product.image" alt="" />
   </router-link>
      </a>
      
      <h5 class="text-black f-large">{{ product.name }}</h5>

      <div class="d-flex flex-row justify-content-between">
        <button @click="addToCart(product)" class="btn w-70 bg-pri text-white radius-10">
          <span class="fa fa-shopping-cart"></span> add to cart
        </button>

        <a href="" class="btn btn-sm w-25 bg-sec radius-10 mx-1 text-white"
          ><span class="fa fa-heart-o"></span
        ></a>
      </div>
    </div>
  
  </div>

</template>
<script>

import { mapActions } from 'vuex';

export default {
  name: "productItem",
  props: ["product"],

  methods: {

  ...mapActions(['addToCart']),
 

     

  },

  data() {
    return {
      cart: [],
    };
  },

  created() {

  },
 
};
</script>

<style>
.product-item {
  transition: 0.5s all cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 30px;
  padding: 0.8rem;
  border: thin solid rgba(128, 128, 128, 0.747);
  position: relative;
}
.product-item .category {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 5px;
  background: #1d1d1b;
  color: white;
  font-size: 0.7rem;
  z-index: 2;
  padding: 5px;
}
.product-item .brand {
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 10px;
  background: #2064a4;
  color: white;
  font-size: 0.7rem;
  z-index: 2;
  padding: 5px;
}

.product-item h5 {
  color: #2064a4;
  font-weight: bold;

  font-size: 1rem;
}
.product-item:hover {
  border: 2px #2064a4 solid;
  box-shadow: #2064a4 5px 5px 0;
}
.product-item img {
  height: 200px;
  width: auto;
  object-fit: contain;
  scale: 0.9;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
}
.product-item img:hover {
  scale: 1;
}
</style>
