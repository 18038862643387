<template>
    <div class="home">
    <appNav/>
    <productSection/>
   
    </div>
  </template>
  
  <script>
    
 import appNav from '@/components/layouts/appNav.vue'
 import productSection from '@/components/productSection.vue'


  export default {
    name: 'Home',
    components: {
      appNav,
      productSection,
  }
  }
  </script>