<template>
<appNav/>

<p>{{product.name}}</p>

</template>
<script>

 import appNav from '@/components/layouts/appNav.vue'

//import { mapGetters } from 'vuex';

  export default {
    name: 'product',
    components: {
      appNav,

  },
 //computed: {

    // ...mapGetters(['getProduct']),
    // product() {
    //   const productId = this.$route.params.id;
    //   return this.items.find(product => product.id === Number(productId));
    // },

    props: ['id'],
    computed: {
      product :function () {
      
        return this.$store.getters.getProduct(this.id);
      }
  //  }
  },
  }
 
  </script>