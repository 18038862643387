<template>


  <RouterView />

</template>

<script>
export default {
  name: "App",
  components: {

  },
  data() {
    return {
     
    };
  },
  methods: {
   
  },
};
</script>

<style>
#app {
  
}
</style>
